import React, {useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import './styles/swiper.css'
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import {EffectFade, Mousewheel, Pagination, Lazy} from "swiper";
import Header from "./components/Header";
import Home from "./components/home";
import Text from "./components/text";
import Audio from "./components/audio";
import Drawing from "./components/drawing";
import Gc from "./components/gc";
import Education from "./components/education";


function App() {

  const [swiper, setSwiper] = useState<any>()
  const [activeIndex, setActiveIndex] = useState(0)
  return (
      <>
        <Header activeIndex={activeIndex} swiper={swiper}/>
        <Swiper
            onSwiper={(_swiper) => {
              setSwiper(_swiper)
            }}
            onActiveIndexChange={({activeIndex}) => setActiveIndex(activeIndex)}
            onChange={event => console.log(11122222, event)}
            direction={"vertical"}
            slidesPerView={1}
            effect={"fade"}
            spaceBetween={0}
            mousewheel={true}
            pagination={{
              clickable: true
            }}
            fadeEffect={{crossFade: true}}
            lazy
            modules={[Mousewheel, Pagination, EffectFade, Lazy]}
            className="mySwiper"
        >
          <SwiperSlide>
            <Home/>
          </SwiperSlide>
          <SwiperSlide>
            <Text/>
          </SwiperSlide>
          <SwiperSlide>
            <Audio/>
          </SwiperSlide>
         {/* <SwiperSlide>
            <Drawing/>
          </SwiperSlide>*/}
          <SwiperSlide>
            <Gc/>
          </SwiperSlide>
          {/*<SwiperSlide>*/}
          {/*  <Education/>*/}
          {/*</SwiperSlide>*/}
        </Swiper>
      </>
  );
}

export default App;
