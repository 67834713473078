import React from 'react';
import styled from "styled-components";
import Container from "../Container";
import {Left, Right, Top} from '../../styles/css'

const LeftBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 8.41rem;
  height: 100vh;
  background: linear-gradient(269deg, #0B1227 0%, rgba(1, 4, 15, 0.73) 100%);
`;
const LeftStyled = styled(Left)`
  height: 100vh;
  object-fit: cover;
`;
export const LeftBottom = styled.img`
  position: absolute;
  z-index: 4;
  top: 7.7rem;
  left: 2.18rem;
`;
const Title = styled.img`
  position: absolute;
  z-index: 3;
  left: 3rem;
  top: 3.7rem;
  height: 3.4rem;
  width: 8.58rem;
`;
const Name = styled.div`
  position: absolute;
  left: 4.04rem;
  top: 2.7rem;
  z-index: 5;
  font-size: .48rem;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 12px;
`;
const Home = () => {
  return (
      <Container>
        <LeftStyled src={'/home/left.png'}/>
        <LeftBg/>
        <Top src={'/home/top.png'}/>
        <LeftBottom src={'/home/left-bottom.png'}/>
        <Title src={'/home/title.png'}/>
        <Right src={'/home/right.png'}/>
        {/*<Name>小嗨AI</Name>*/}
      </Container>
  );
};

export default Home;
