import React from 'react';
import styled from "styled-components";

const HeaderStyled = styled.header`
  position: absolute;
  z-index: 100;
  top: .58rem;
  padding: 0 2.4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Icon = styled.img`
  display: block;
  cursor: pointer;
  width: 1.74rem;
  height: .48rem;
`;
const Menu = styled.div`
  display: flex;
  align-items: center;
`;
const MenuItem = styled.div<{active:boolean}>`
  position: relative;
  font-size: .16rem;
  color: #FFF;
  cursor: pointer;
  white-space: nowrap;
  
  &::after {
    content: '';
    position: absolute;
    width: .12rem;
    height: .02rem;
    background: #fff;
    border-radius: .25rem;
    bottom: -.05rem;
    left: 50%;
    transform: translateX(-50%);
    display: ${props => props.active ? 'block' : 'none'};
  }

  &:not(:last-child) {
    margin-right: .98rem;
  }
`;
const menu = [
  {
    index: 0,
    name: '小嗨介绍'
  },
  {
    index: 1,
    name: '小嗨AI'
  },
  // {
  //   name: '联系我们'
  // },
].map((item, i) => ({...item, id: i + 1}))
const Header = ({swiper, activeIndex}) => {
  const handleActive = (index:number)=> {
    if(index === 1) {
      return [1,2,3,4,5].includes(activeIndex)
    }
    return activeIndex === index
  }
  return (
      <HeaderStyled>
        <Icon src={'/home/logo.png'} width={210} height={50} onClick={() => {
          if (activeIndex === 0) {
            window.location.reload()
          } else {
            swiper.slideTo(0)
          }
        }}/>
        <Menu>
          {/*{
            menu.map(item => (
                <MenuItem active={handleActive(item.index)} key={item.id} onClick={() => {
                  if (item?.index !== undefined) {
                    swiper.slideTo(item.index)
                  }
                }}>{item.name}</MenuItem>
            ))
          }*/}
        </Menu>
      </HeaderStyled>
  );
};

export default Header;
