import styled from "styled-components";

export const Left = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  width: 10.61rem;
  height: 10.1rem;
`;
export const Right = styled.img`
  position: absolute;
  z-index: 2;
  top: 2.39rem;
  left: 6.82rem;
  width: 12.13rem;
  height: 6.98rem;
`;
export const Top = styled.img`
  position: absolute;
  z-index: 4;
  top: -30px;
  left: 5.88rem;
  width: 9.51rem;
  height: 4.15rem;
`;
export const Content = styled.div`
  position: absolute;
  left: 4.69rem;
  top: 2.9rem;
  z-index: 5;
`;
export const Title = styled.div`
  background-image: url('/text/title.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top left;
  width: 4.11rem;
  height: .95rem;
  line-height: .95rem;
  text-align: center;
  font-size: .5rem;
  font-weight: bold;
  color: #FFF;
  letter-spacing: .09rem;
  margin-bottom: .6rem;
`;
export const Desc = styled.div`
  font-size: .46rem;
  background: linear-gradient(295deg, #BFCDFF 0%, #FFFFFF 100%);
  margin-bottom: .1rem;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
export const Sub = styled.div`
  width: 4.74rem;
  font-size: .24rem;
  color: #FFF;
  line-height: .47rem;
  margin-bottom: .8rem;
`;
export const Button = styled.div`
  position: absolute;
  top: 4rem;
  left: 0;
  background-image: url('/text/button.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top left;
  width: 2.09rem;
  height: .64rem;
  line-height: .64rem;
  font-size: .24rem;
  font-weight: bold;
  color: #FFF;
  cursor: pointer;

  span {
    margin-left: .32rem;
  }
`;
