import {createGlobalStyle} from 'styled-components';

export const GlobalStyled = createGlobalStyle<any>`
  html,
  body {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
    color: #fff;
    background-color: #02091F;
  }

  html {
    /* h5端开启，开启后使用rem代替px*/
    font-size: calc(100vw / 19.2);
  }

  body {
    position: relative;
  }

  #__next {
    overflow-x: hidden;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:active {
      background: none;
    }
  }

  * {
    box-sizing: border-box;
  }

  ul {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    list-style: none;
  }
`;
