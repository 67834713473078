import React from 'react';
import styled from "styled-components";

const ContainerStyled = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  min-width: 14.6rem;
`;
const Container = ({children}) => {
  return (
    <ContainerStyled>
      {children}
    </ContainerStyled>
  );
};

export default Container;
