import React from 'react';
import Container from "../Container";
import {Left, Right, Content, Title, Desc, Sub, Button, Top} from '../../styles/css'
import styled from "styled-components";

const Bottom = styled.img`
  position: absolute;
  z-index: 6;
  width: 2.19rem;
  height: 2.53rem;
  left: 13.63rem;
  top: 8.14rem;
`;
const LeftStyled = styled(Left)`
  width: 10.59rem;
  height: 10.8rem;
`;
const RightStyled = styled(Right)`
  top: 2.39rem;
`;
const Text = () => {
  return (
      <Container>
        <LeftStyled src={'/text/left.png'} loading={'lazy'}/>
        <RightStyled src={'/text/right.png'} loading={'lazy'}/>
        <Top src={'/home/top.png'} loading={'lazy'}/>
        <Content style={{top: '3.6rem'}}>
          <Title>AI文本推断</Title>
          <Desc>
            辅助创作，查漏补缺
          </Desc>
          <Sub>
            AI 诗歌创作 AI歌词创作为文字创作者提供<br/>全方位的文字AI辅助能力
          </Sub>
          {/*<a href={'https://xiaohi.xingzheai.cn'} target={'_blank'} rel="noreferrer">*/}
          {/*  <Button>*/}
          {/*  <span>*/}
          {/*    立即体验*/}
          {/*  </span>*/}
          {/*  </Button>*/}
          {/*</a>*/}
        </Content>
        <Bottom src={'/text/bottom.png'} loading={'lazy'}/>
      </Container>
  );
};

export default Text;
