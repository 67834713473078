import React from 'react';
import Container from "../Container";
import {Left, Right, Content, Title, Desc, Sub, Button, Top} from '../../styles/css'
import styled from "styled-components";

const RightStyled = styled(Right)`
  top: 2.35rem;
`;
const LeftStyled = styled(Left)`
  width: 10.59rem;
  height: 10.8rem;
`;
const Audio = () => {
  return (
      <Container>
        <LeftStyled src={'/audio/left.png'} loading={'lazy'}/>
        <RightStyled src={'/audio/right.png'} loading={'lazy'}/>
        <Top src={'/home/top.png'} loading={'lazy'}/>
        <Content style={{top: '3.6rem'}}>
          <Title>AI·音频</Title>
          <Desc>
            人声分离 ，虚拟歌手
          </Desc>
          <Sub>
            AI 音频生成 作曲、编曲全流程覆盖
          </Sub>
          {/*<a href={'https://poetry.xingzheai.cn'} target={'_blank'} rel="noreferrer">*/}
          {/*  <Button>*/}
          {/*  <span>*/}
          {/*    立即体验*/}
          {/*  </span>*/}
          {/*  </Button>*/}
          {/*</a>*/}
        </Content>
      </Container>
  );
};

export default Audio;
