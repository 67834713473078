import React from 'react';
import Container from "../Container";
import {Right} from '../../styles/css'

import styled from "styled-components";

export const Content = styled.div`
  position: absolute;
  z-index: 3;
  left: 3.7rem;
  top: 3.7rem;
`;
export const Title = styled.div`
  font-size: .71rem;
  font-weight: bold;
  color: #FFF;
  letter-spacing: .07rem;
  margin-bottom: .1rem;
`;
export const Desc = styled.div`
  width: 6.03rem;
  font-size: .27rem;
  color: #FFF;
  margin-top: .3rem;
`;
const RightStyled = styled(Right)`
  height: 6.86rem;
`;
const ContentStyled = styled(Content)`
  top: 3.4rem;
`;
const Gc = () => {
  return (
      <Container>
        <ContentStyled>
          <Title>
            AIGC跨模态生成
          </Title>
          <Title>
            内容全覆盖AI输出
          </Title>
          <Desc>
            实现文本、图片、音频、视频多种模态交互协作
          </Desc>
        </ContentStyled>
        <RightStyled src={'/gc/right.png'} loading={'lazy'}/>
      </Container>
  );
};

export default Gc;
